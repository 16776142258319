<template>
  <div class="step__block-partitionglass-block">
    <slot />
  </div>
</template>

<style scoped>
.step__block-partitionglass-block {
  display: grid;
  grid-template-columns: 25% 20% repeat(4, 1fr);
  column-gap: 15px;
  color: #404d63;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  column-gap: 15px;
}
</style>
