<template>
  <div class="step__block-partitionglass-subblock">
    <PriceValue :price="price" />
  </div>
</template>

<script setup lang="ts">
import { ref, defineModel, watch, computed } from "vue";

import { Pricing } from "@/app/domain";
import { PriceValue } from "@/shared";
import { useDictStore } from "@/stores/dictStore";
import { useNewEstimateStore } from "@/stores/new/estimateStore";

type GlassAdditionalPriceProps = {
  priceKey: "priceEdging" | "priceGlass";
};

const dictStore = useDictStore();
const estimateStore = useNewEstimateStore();

const props = defineProps<GlassAdditionalPriceProps>();
const price = ref<Pricing | null>(null);

const glassStructureCode = defineModel<string>("structureCode");
const glassThinknessCode = defineModel<string>("thinknessCode");

const stateCode = computed(() => estimateStore.estimate.shippingState);

watch(
  () => [glassStructureCode.value, glassThinknessCode.value],
  () => {
    if (
      !glassStructureCode.value ||
      !glassThinknessCode.value ||
      !stateCode.value
    ) {
      return;
    }

    const pricesFromDict = dictStore.findGlassPrice(
      glassStructureCode.value,
      glassThinknessCode.value,
      stateCode.value,
    );

    if (!pricesFromDict) return;

    price.value = new Pricing(
      pricesFromDict[props.priceKey],
      pricesFromDict[props.priceKey],
      pricesFromDict[props.priceKey],
    );
  },
  { immediate: true },
);
</script>

<style lang="scss" scoped>
.step__block-partitionglass-subblock span {
  font-size: 14px !important;
  font-weight: normal !important;
}

.step__block-partitionglass-subblock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  text-align: right;
}
</style>
