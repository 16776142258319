import {
    DividerPartitionCode,
    DoorPartitionCode,
    GlassPartitionCode,
    MultiFoldingPartitionCode,
} from "@/app/constants";
import { IComponentConfig } from "@/app/@types/base-component";
import { ReactiveComponent } from "./base-component";
import { Door } from "./door";
import { Overdoor } from "./overdoor";
import { Mounts } from "./mounts";
import { DimensionValue } from "./dimensions";

export class Partition extends ReactiveComponent {
    constructor(config: IComponentConfig) {
        super({ ...config, type: "partition" });
    }

    substractDoorIfExistAndUpdateGlassArea = (door: Door | null): void => {
        if (!door) return;

        const doorDimensions = door.dimensions.copy();
        const partitionDimensions = this.dimensions.copy();

        const partitionArea = partitionDimensions.calculateArea().getValue();
        const doorArea = doorDimensions.calculateArea().getValue();

        const newPartitionArea = partitionArea - doorArea;

        const glass = this.getActiveGlass();

        glass!.dimensions.area = new DimensionValue(newPartitionArea);
    };

    substractOverdoorIfExistAndUpdateGlassArea = (
        overdoor: Overdoor | null,
        door: Door | null,
    ): void => {
        if (!overdoor || !door) return;

        const overdoorDimensions = overdoor?.dimensions.copy();
        const doorDimensions = door?.dimensions.copy();
        const partitionDimensions = this.dimensions.copy();

        const partitionArea = partitionDimensions.calculateArea().getValue();
        const doorArea = doorDimensions.calculateArea().getValue();
        const overdoorArea = overdoorDimensions.calculateArea().getValue();

        const newPartitionArea = partitionArea - doorArea - overdoorArea;

        const glass = this.getActiveGlass();

        glass!.dimensions.area = new DimensionValue(newPartitionArea);
    };

    isDivider = (): boolean => {
        return this.structureType?.value === DividerPartitionCode;
    };

    isGlassPartition = (): boolean => {
        return this.structureType?.value === GlassPartitionCode;
    };

    isDoorPartition = (): boolean => {
        return this.structureType?.value === DoorPartitionCode;
    };

    isMultiFoldingPartition = (): boolean => {
        return this.structureType?.value === MultiFoldingPartitionCode;
    };

    getAllOverdoors = () => {
        return this.components.filter(
            (component) => component.type === "overdoor",
        );
    };

    getExtra = () => {
        return this.components.find((component) => component.type === "extra");
    };

    getAllDoors = () => {
        return this.components.filter((component) => component.type === "door");
    };

    getActiveDoor = (): Door => {
        return this.components.find(
            (component) => component.type === "door",
        ) as unknown as Door;
    };

    getActiveOverdoor = () => {
        return this.components.find(
            (component) => component.type === "overdoor",
        ) as unknown as Overdoor;
    };

    getActiveMount = () => {
        return this.components.find(
            (component) => component.type === "mounts",
        ) as unknown as Mounts;
    };

    getActiveGlass = () => {
        return this.components.find(
            (component) => component.type === "glassPartition",
        );
    };

    getActiveGlassThinkness = () => {
        const glass = this.getActiveGlass();
        return glass?.components.find(
            (component) => component.type === "glassThinknessPartition",
        );
    };

    getActiveOutOfSquare = () => {
        return this.components.find(
            (component) => component.type === "outOfSquare",
        );
    };
}
