import { B2BDealType, B2CDealType, CostDealType } from "@/app/constants";

import { PricingStrategy, PriceResult } from "../base/pricing.strategy";
import { ReactiveComponent } from "../../entities/base-component";

export class PartitionPricingStrategy extends PricingStrategy {
    public getPrices(component: ReactiveComponent): PriceResult {
        return {
            price: this.calculateComponentsPriceForType(
                component,
                CostDealType,
            ),
            priceB2C: this.calculateComponentsPriceForType(
                component,
                B2CDealType,
            ),
            priceB2B: this.calculateComponentsPriceForType(
                component,
                B2BDealType,
            ),
        };
    }

    private calculateComponentsPriceForType(
        component: ReactiveComponent,
        dealType: string,
    ) {
        if (!component) return 0;

        const children = component.components;

        return children.reduce((total, child) => {
            const sum = child.sum.getSystemPrice(dealType).getValue();

            return total + sum;
        }, 0);
    }
}
