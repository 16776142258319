<template>
  <div
    class="step__block-partitionglass"
    v-if="activeGlassOverdoor && activeGlassThinknessOverdoor"
  >
    <GlassPriceTableRow>
      <div>
        <span>Glass sort:</span>
      </div>
      <GlassPriceTableCell>
        <span>Glass thickness:</span>
      </GlassPriceTableCell>
      <GlassPriceTableCell>
        <span>Glass price:</span>
      </GlassPriceTableCell>
      <GlassPriceTableCell>
        <span>Edge/Film:</span>
      </GlassPriceTableCell>
      <GlassPriceTableCell>
        <span>Per sq.ft.</span>
      </GlassPriceTableCell>
      <GlassPriceTableCell>
        <span>SUM</span>
      </GlassPriceTableCell>
    </GlassPriceTableRow>
    <GlassPriceTableRow class="step__block-partitionglass-block--selects">
      <div>
        <GlassSortSelect v-model="activeGlassOverdoor.structureType" />
      </div>
      <GlassPriceTableCell>
        <GlassThinknessSelect
          v-model="activeGlassThinknessOverdoor.structureType"
        />
      </GlassPriceTableCell>
      <GlassPriceTableCell>
        <GlassAdditionalPrice
          price-key="priceGlass"
          v-model:structure-code="activeGlassOverdoor.structureType"
          v-model:thinkness-code="activeGlassThinknessOverdoor.structureType"
        />
      </GlassPriceTableCell>
      <GlassPriceTableCell>
        <GlassAdditionalPrice
          price-key="priceEdging"
          v-model:structure-code="activeGlassOverdoor.structureType"
          v-model:thinkness-code="activeGlassThinknessOverdoor.structureType"
        />
      </GlassPriceTableCell>
      <GlassPriceTableCell>
        <span>
          {{ activeGlassOverdoor.price.getSystemPrice(dealTypeCode).format() }}
        </span>
      </GlassPriceTableCell>
      <GlassPriceTableCell>
        <span>
          {{ activeGlassOverdoor.sum.getSystemPrice(dealTypeCode).format() }}
        </span>
      </GlassPriceTableCell>
    </GlassPriceTableRow>
  </div>
</template>

<script setup lang="ts">
import { computed, watch } from "vue";

import {
  GlassSortSelect,
  GlassThinknessSelect,
  GlassAdditionalPrice,
  GlassPriceTableCell,
  GlassPriceTableRow,
} from "@/shared";

import { PricingService } from "@/app/services/pricing.service";
import { useOverdoorStore } from "@/stores/new/overdoor.store";
import { useNewEstimateStore } from "@/stores/new/estimateStore";

const estimateStore = useNewEstimateStore();
const overdoorStore = useOverdoorStore();

const pricingService = new PricingService();

const estimate = computed(() => estimateStore.estimate);
const dealTypeCode = computed(() => estimate.value.dealTypeCode);

const activeOverdoor = computed(() => overdoorStore.activeOverdoor);

const activeGlassOverdoor = computed(() =>
  overdoorStore.activeOverdoorMaterials.at(0),
);
const activeGlassThinknessOverdoor = computed(() =>
  overdoorStore.activeOverdoorMaterials.at(1),
);

watch(
  () => [
    activeGlassOverdoor.value?.structureType,
    activeGlassThinknessOverdoor.value?.structureType,
  ],
  () => {
    const price = pricingService.getOverdoorGlassPrices();
    activeGlassOverdoor.value?.update({ price });
  },
  { immediate: true, deep: true },
);

watch(
  () => [activeGlassOverdoor.value?.price, activeOverdoor.value?.dimensions],
  () => {
    const sum = pricingService.calculateOverdoorGlassPrice();

    activeGlassOverdoor.value?.update({ sum });
  },
  { immediate: true, deep: true },
);
</script>

<style lang="scss" scoped>
.step__block-partitionglass {
  padding: 15px 0;
}

.step__block-partitionglass-block--selects {
  padding-top: 10px;
}
</style>
