<template>
  <div v-if="activeGlassDoor && activeGlassThinknessDoor">
    <div class="step__block-partitionglass">
      <GlassPriceTableRow>
        <div>
          <span>Glass sort:</span>
        </div>
        <GlassPriceTableCell>
          <span>Glass thickness:</span>
        </GlassPriceTableCell>
        <GlassPriceTableCell>
          <span>Glass price:</span>
        </GlassPriceTableCell>
        <GlassPriceTableCell>
          <span>Edge/Film:</span>
        </GlassPriceTableCell>
        <GlassPriceTableCell>
          <span>Per sq.ft.</span>
        </GlassPriceTableCell>
        <GlassPriceTableCell>
          <span>SUM</span>
        </GlassPriceTableCell>
      </GlassPriceTableRow>
      <GlassPriceTableRow class="step__block-partitionglass-block--selects">
        <div>
          <GlassSortSelect v-model="activeGlassDoor.structureType" />
        </div>
        <GlassPriceTableCell>
          <DoorGlassThinknessSelect
            v-model="activeGlassThinknessDoor.structureType"
          />
        </GlassPriceTableCell>
        <GlassPriceTableCell>
          <GlassAdditionalPrice
            price-key="priceGlass"
            v-model:structure-code="activeGlassDoor.structureType"
            v-model:thinkness-code="activeGlassThinknessDoor.structureType"
          />
        </GlassPriceTableCell>
        <GlassPriceTableCell>
          <GlassAdditionalPrice
            price-key="priceEdging"
            v-model:structure-code="activeGlassDoor.structureType"
            v-model:thinkness-code="activeGlassThinknessDoor.structureType"
          />
        </GlassPriceTableCell>

        <GlassPriceTableCell>
          <PriceValue :price="activeGlassDoor.price" />
        </GlassPriceTableCell>
        <GlassPriceTableCell>
          <PriceValue :price="activeGlassDoor.sum" />
        </GlassPriceTableCell>
      </GlassPriceTableRow>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, watch } from "vue";

import {
  GlassPriceTableRow,
  GlassPriceTableCell,
  GlassSortSelect,
  PriceValue,
  GlassAdditionalPrice,
} from "@/shared";

import { PricingService } from "@/app/services/pricing.service";
import { useDoorsStore } from "@/stores/new/doors.store";
import { DoorGlassThinknessSelect } from "@/modules/doors";

const doorsStore = useDoorsStore();

const pricingService = new PricingService();

const activeDoor = computed(() => doorsStore.activeDoor);

const activeGlassDoor = computed(() => doorsStore.activeGlassDoor);
const activeGlassThinknessDoor = computed(
  () => doorsStore.activeGlassThinknessDoor,
);

watch(
  () => [
    activeGlassDoor.value?.structureType,
    activeGlassThinknessDoor.value?.structureType,
  ],
  () => {
    const price = pricingService.getDoorGlassPrices();

    activeGlassDoor.value?.update({ price });
  },
  { immediate: true, deep: true },
);

watch(
  () => [activeGlassDoor.value?.price, activeDoor.value?.dimensions.area],
  () => {
    const sum = pricingService.calculateDoorGlassPrice();
    activeGlassDoor.value?.update({ sum });
  },
  { immediate: true, deep: true },
);
</script>

<style lang="scss" scoped>
.step__block-partitionglass-subblock span {
  font-size: 14px !important;
  font-weight: normal !important;
}

.step__block-partitionglass {
  padding: 15px 0;
}

.step__block-partitionglass-block--selects {
  padding-top: 10px;
}
</style>
